import { GQLogo } from '../assets/svg/gq';
import { ADLogo } from '../assets/svg/ad';
import { HGLogo } from '../assets/svg/hg';
import { LCILogo } from '../assets/svg/lci';
import { GlamourLogo } from '../assets/svg/glamour';
import { PitchForkLogo } from '../assets/svg/pitch-fork';
import { SelfLogo } from '../assets/svg/self';
import { WiredLogo } from '../assets/svg/wired';
import { AllureLogo } from '../assets/svg/allure';
import { TeenVogueLogo } from '../assets/svg/teen-vogue';
import { TatlerLogo } from '../assets/svg/tatler';
import { TheNewYorkerLogo } from '../assets/svg/the-new-yorker';
import { BALogo } from '../assets/svg/ba';
import { VogueLogo } from '../assets/svg/vogue';
import { VanityFairLogo } from '../assets/svg/vanity-fair';
import { EpicuriousLogo } from '../assets/svg/epicurious';
import { TheWorldOfInteriorLogo } from '../assets/svg/the-world-of-interior';
import { CNTLogo } from '../assets/svg/cnt';
import { ThemLogo } from '../assets/svg/them';
import { VogueBusinessLogo } from '../assets/svg/vogue-business';

const componentList = [
    { name: 'GQ', logo: <GQLogo />, href: '/gq' },
    { name: 'Architectural Digest', logo: <ADLogo />, href: '/ad' },
    { name: 'House & Garden', logo: <HGLogo />, href: '/h&g' },
    { name: 'LaCucina', logo: <LCILogo />, href: '/lci' },
    { name: 'Glamour', logo: <GlamourLogo />, href: '/glamour' },
    { name: 'Pitchfork', logo: <PitchForkLogo />, href: '/pitchfork' },
    { name: 'Self', logo: <SelfLogo />, href: '/self' },
    { name: 'Wired', logo: <WiredLogo />, href: '/wired' },
    { name: 'Allure', logo: <AllureLogo />, href: '/allure' },
    { name: 'Teen Vogue', logo: <TeenVogueLogo />, href: '/teenvogue' },
    { name: 'Tatler', logo: <TatlerLogo />, href: '/tatler' },
    { name: 'The New Yorker', logo: <TheNewYorkerLogo />, href: '/tny' },
    { name: 'Bon Appetit', logo: <BALogo />, href: '/ba' },
    { name: 'Vogue', logo: <VogueLogo />, href: '/vogue' },
    { name: 'Vanity Fair', logo: <VanityFairLogo />, href: '/vanityfair' },
    { name: 'Epicurious', logo: <EpicuriousLogo />, href: '/epi' },
    { name: 'The World of Interiors', logo: <TheWorldOfInteriorLogo />, href: '/woi' },
    { name: 'CN Traveler', logo: <CNTLogo />, href: '/cnt' },
    { name: 'Them', logo: <ThemLogo />, href: '/them' },
    { name: 'Vogue Business', logo: <VogueBusinessLogo />, href: '/voguebusiness' },
];

export default componentList;