import { object } from "prop-types";
import { ALL_SITE_LIST } from "./Constants";

// count object to count the occurrences of each value
const count = Object.values(ALL_SITE_LIST).reduce((acc, val) => {
  acc[val] = (acc[val] || 0) + 1;
  return acc;
}, {});

// unique values (count === 1)
const uniqueValues = Object.entries(ALL_SITE_LIST).reduce((acc, [key, value]) => {
  if (count[value] === 1) {
    acc[value] = key;
  }
  return acc;
}, {});


export const CACHE_ENABLED_BRANDS = ['vogue', 'vanityfair', 'glamour', 'gq'];

export const getJsonDataPath = ({ brandName, filters }) => {

  let siteName =
    filters.site === 'All Traffic' ? 'All-Traffic' : filters.site;
    if (filters.site === 'All Traffic') {
      // if this brand has only one site
      if (Object.keys(uniqueValues).includes(brandName)) {
        siteName = uniqueValues[brandName]
      }
    }
  const trimmedSite = siteName.replace(/\s+/g, '');
  return `${brandName}/${trimmedSite}/pages/${filters.view}`;
  // return CACHE_ENABLED_BRANDS.includes(brandName)
  //   ? `${brandName}/${siteName}/pages/${filters.view}`
  //   : null;
};
