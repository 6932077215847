import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { getJsonDataPath, CACHE_ENABLED_BRANDS } from '../Utils/jsonDataPath';
import { CACHED_S3_URL } from '../Utils/Constants';
import { useSession } from './SessionContext';
import { logS3CachedDataFetchError } from '../Utils/Logger/Logger';

// Create Context
export const DataContext = createContext({
  filters: {}, // Default value
  brandName: '', // updating the context
});

// Create a Provider component
export const DataProvider = ({ children, value }) => {
  const { filters } = value;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cachedData, setCachedData] = useState({});
  const { brandName, customFilters, dateTimeFiltersApplied } = useSession();
  const inProgress = useRef();

  const getData = useCallback(
    ({ brandName, filters }) => {
      try {
        const jsonDataPath = getJsonDataPath({
          brandName,
          filters,
        });
        const fetchKpiData = async () => {
          if ((!cachedData || (cachedData && !cachedData[filters.view]))) {
            const response = await fetch(`${CACHED_S3_URL}${jsonDataPath}`, {
              mode: 'cors',
            }).catch((error) => {
              logS3CachedDataFetchError({errorInfo: error})
            });
            if (response) {
              const jsonData = await response.json();
              setCachedData({ ...cachedData, [filters.view]: jsonData });
              inProgress.current = false;
            }
          }
        };
        if (jsonDataPath && customFilters === 0 && !dateTimeFiltersApplied && !inProgress.current) {
          fetchKpiData();
          inProgress.current = true;
        }
      } catch (error) {
        console.log('Error in fetching cached data', error);
      }
    },
    [cachedData, customFilters, dateTimeFiltersApplied]
  );

  useEffect(() => {
    inProgress.current = false;
  },[filters.view]);

  useEffect(() => {
    if (brandName && filters && filters.site && filters.view) {
      getData({ brandName, filters });
    }
  }, [brandName, filters, getData]);

  return (
    <DataContext.Provider
      value={{
        data,
        loading,
        error,
        cachedData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
