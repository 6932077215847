export const configs = [
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'global',
    appname: '40d0efce-3a07-4bc4-9963-1c17f6b87bd5',
    // appname: '994e5bcf-c8d3-468c-a450-8f46f4181b68',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vogue',
    appname:'9eac0f9e-e22d-4196-9493-42a9ac383fcc',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'ad',
    appname:'4ee7a55e-03e0-47de-8939-7be84c4590dc',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vanityfair',
    appname:'4e240f12-8af9-46bd-99a2-31a2e5b58cfa',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'allure',
    appname: '8596d9e1-5c76-4390-8c07-47f531b7c73b',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'cnt',
    appname: '2e96349a-10c2-44af-a163-c4ee92dc2b5c',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'glamour',
    appname: 'f16e466d-7810-48a9-9ed5-88d14bdc33ea',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'gq',
    appname: '35ac3f2d-221f-49e0-baa1-92432a532db5',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'tny',
    appname: 'a327c926-9170-4ce6-8624-96a59ad27c36',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'wired',
    appname: '3aa55cdc-216d-4d26-855b-49dcaee44607',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'voguebusiness',
    appname: '57aa4d79-9ec6-4c9d-9e96-e6c57be2697f',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'teenvogue',
    appname: '29799bcd-5d90-4e88-a99a-4647f89ae299',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'ba',
    appname: 'd67654f2-99e9-48ec-b792-095057f1b607',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'epi',
    appname: '6e90a692-f90d-441d-9f6c-1da8e628d4d0',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'tatler',
    appname: '83273641-28ce-4962-8935-cacb12344314',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'lci',
    appname: 'a38ba585-6b85-4e8c-9e74-4447adb5fd3e',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'woi',
    appname: '08242e6c-a9ef-4e1d-bf90-3a6dee0cc181',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'pitchfork',
    appname: 'a9c48d73-aaff-476a-b6a1-534e7fb6b08f',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'self',
    appname: '513f46e7-c336-4715-ab8c-fc8bea51e826',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'them',
    appname: '5664c594-dd72-44e0-8c3c-0e9b721baa00',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'h&g',
    appname: '8410090b-5644-4f30-a0d1-0686df36bb0c',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
  },

  // year based story view
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vogue',
    appname: '3a49136a-d814-46d5-a6b2-3100620dc98c',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2024'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vogue',
    appname: '72b49529-5123-4a3c-bfb8-72efd5a9d1b2',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2023'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vogue',
    appname: 'd4861839-5c33-4637-8cc3-e73e09f6d027',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2022'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vogue',
    appname: '6f75f1f0-bc1f-4545-a591-8e7b9de2259c',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2021'
  },
  // GQ year wise apps
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'gq',
    appname: 'c54851e6-4af3-43d0-afdf-899696fb0b18',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2024'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'gq',
    appname: '261abf77-0db4-4155-b289-58e44a9ebba4',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2023'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'gq',
    appname: 'ca2ab588-161a-41e6-bd06-a52380858898',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2022'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'gq',
    appname: 'a283d435-81bc-4c47-ac3d-c0fff7172620',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2021'
  },
  // vanity fair year wise apps
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vanityfair',
    appname: '80cce357-ba66-4428-89ad-a49122064206',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2024'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vanityfair',
    appname: '026b8050-5678-42cf-8844-19d9ad909de7',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2023'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'vanityfair',
    appname: 'fb5ad130-44fd-46af-bad3-9ea691b46c31',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2022'
  },
  // Glamour year wise apps
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'glamour',
    appname: 'b2a54679-8e64-4105-b087-4f1cc1bc1106',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2024'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'glamour',
    appname: '01400958-27e4-4927-88ba-f017e1c115b7',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2023'
  },
  {
    host: 'dash.us.qlikcloud.com',
    isSecure: true,
    brand: 'glamour',
    appname: 'fea11c9b-abf9-4768-bbe0-56311b61c420',
    webIntegrationId: 'EpQEeQ9UBmHTbaKhAyuSYQIcwG6uK8It',
    isCloud: true,
    pubYear: '2022'
  },
];
