import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsBrandView = () => {
  const location = useLocation();
  const [brandView, setBrandView] = useState(location?.pathname === '/app');

  useEffect(() => {
    setBrandView(location?.pathname === '/app');
  }, [location.pathname]);
  return { isbrandView : brandView};
};
