import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import './index.css';
import { hyperCubesConfig } from './Config/hyperCubes';
import Loader from './Components/Loader';

//import { LoginCallback } from '@okta/okta-react';
import LoginCallback from './LoginCallback';
import Redirector from './Pages/Redirector';
import { RequiredAuth } from './SecureRoute';
import { setCookie, getCookie } from './Utils/functions';
import { AuthProvider } from 'react-oauth2-code-pkce';
import { nanoid } from 'nanoid';
import { lazyWithRetry } from './Utils/lazyWithRetry';
import { UserProvider } from './Context/UserContext';

const SinglePage = lazyWithRetry(() => import('./Pages/SinglePage'));
const StoryPage = lazyWithRetry(() => import('./Pages/StoryPage'));
const Test = lazyWithRetry(() => import('./Pages/Test'));
const PageNotFound = lazyWithRetry(() => import('./Pages/PageNotFound'));

const CLIENT_ID = process.env.CLIENT_ID || '0oad3b9kkmDiNHwVf0i7';
const ISSUER =
  process.env.ISSUER || 'https://condenast-hub.okta-emea.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/`;

const oktaAuth = new OktaAuth({
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
});

const App = ({ isGlobalApp }) => {
  //find base url
  let basePath = getCookie('base');
  if (!basePath || window.location.pathname !== basePath)
    setCookie('base', window.location.pathname);

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const [loggedInUser, setLoggedInUser] = useState();

  const authConfig = {
    clientId: '6ccb9cb874cdcddfb200e1888ffe21c9',
    authorizationEndpoint: 'https://dash.us.qlikcloud.com/oauth/authorize',
    tokenEndpoint: 'https://dash.us.qlikcloud.com/oauth/token',
    redirectUri: `${window.location.origin}/oauth`,
    scope: 'user_default',
    // onRefreshTokenExpire: (event) => event.logIn(undefined, undefined, "popup"),
    state: nanoid(),
    storage: 'local',
    storageKeyPrefix: 'beacon_insights_',
    postLogin: () => {
      window.location.href = basePath;
    },
    clearURL: false,
  };

  useEffect(() => {
    if (oktaAuth) {
      oktaAuth.token.getUserInfo().then((info) => {
        if (info && info.email) {
          setLoggedInUser(info.email);
        }
      });
    }
  }, [setLoggedInUser]); // Update if authState changes

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthProvider authConfig={authConfig}>
        <UserProvider initialUserEmail={loggedInUser}>
          <Routes>
            <Route
              path="/"
              element={
                <LoginCallback homePath={'/auth'} loadingElement={<Loader />} />
              }
            />

            <Route path="/auth" element={<Redirector />} />

            <Route path="/:app" element={<RequiredAuth />}>
              <Route
                path=""
                element={
                  <Suspense fallback={<Loader />}>
                    <SinglePage isGlobalApp={isGlobalApp} />
                  </Suspense>
                }
              ></Route>
            </Route>
            <Route
              path="/:app/:pubYear/story/:siteParam/:contentId"
              element={<RequiredAuth />}
            >
              <Route
                path=""
                element={
                  <Suspense fallback={<Loader />}>
                    <StoryPage
                      hyperCubes={
                        hyperCubesConfig.storyView.daily.Channels.table
                          .hyperCubes
                      }
                      isGlobalApp={isGlobalApp}
                    />
                  </Suspense>
                }
              ></Route>
            </Route>
            <Route
              path="/:app/story/:siteParam/:contentId"
              element={<RequiredAuth />}
            >
              <Route
                path=""
                element={
                  <Suspense fallback={<Loader />}>
                    <StoryPage
                      hyperCubes={
                        hyperCubesConfig.storyView.daily.Channels.table
                          .hyperCubes
                      }
                      isGlobalApp={isGlobalApp}
                    />
                  </Suspense>
                }
              ></Route>
            </Route>
            <Route path="/:app/test" element={<RequiredAuth />}>
              <Route
                path=""
                element={
                  <Suspense fallback={<Loader />}>
                    <Test />
                  </Suspense>
                }
              ></Route>
            </Route>
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </UserProvider>
      </AuthProvider>
    </Security>
  );
};

export default App;
