export const VogueBusinessLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="48" viewBox="0 0 78 48" fill="none">
    <path d="M16.2233 39.8424H13.4204V31.4793H15.8392C17.5576 31.4793 18.6876 32.2474 18.6876 33.6606C18.6876 34.2933 18.3934 34.9826 17.693 35.3667V35.4011C18.9474 35.8418 19.1394 36.9152 19.1394 37.3904C19.1394 39.1408 17.794 39.8424 16.2233 39.8424ZM15.8848 32.8112H14.8901V34.8681H15.9869C16.5064 34.8681 17.2302 34.7438 17.2302 33.828C17.229 33.0045 16.5064 32.8112 15.8848 32.8112ZM16.1211 36.1569H14.8889V38.5081H16.1322C16.7538 38.5081 17.6586 38.3838 17.6696 37.3104C17.6696 36.3047 16.8117 36.1569 16.1211 36.1569Z" fill="black"/>
    <path d="M25.9651 39.0399C25.4001 39.6615 24.6319 40 23.7383 40C23.1056 40 22.122 39.7858 21.4782 39.0509C20.8566 38.3382 20.7101 37.6723 20.7101 36.6432V31.4781H22.2365V36.6321C22.2365 37.1516 22.2587 37.74 22.6095 38.1462C22.8926 38.5192 23.3333 38.6546 23.7284 38.6546C24.1691 38.6546 24.6098 38.5081 24.8929 38.1117C25.1871 37.7043 25.2093 37.1848 25.2093 36.6419V31.4768H26.7357V36.6309C26.7344 37.7732 26.4858 38.4625 25.9651 39.0399Z" fill="black"/>
    <path d="M31.209 40C29.3441 40 28.4959 38.9488 28.4061 37.4236H29.9201C29.9989 38.4071 30.7006 38.7124 31.1979 38.7124C31.8872 38.7124 32.4411 38.1696 32.4411 37.5024C32.4411 36.6099 31.7063 36.3834 30.655 35.9883C29.9989 35.7618 28.5525 35.2756 28.5525 33.6938C28.5525 32.213 29.8857 31.3205 31.2311 31.3205C32.0115 31.3205 32.8018 31.6147 33.288 32.1908C33.6955 32.677 33.7743 33.1633 33.8075 33.6039H32.3045C32.2368 33.1743 31.9771 32.6093 31.1856 32.6093C30.5639 32.6093 30.0666 33.0611 30.0666 33.6606C30.0666 34.4287 30.5984 34.5986 31.9537 35.1303C33.3779 35.6843 33.954 36.4979 33.954 37.5036C33.9552 38.7678 33.0849 40 31.209 40Z" fill="black"/>
    <path d="M35.718 39.8424V31.4793H37.232V39.8424H35.718Z" fill="black"/>
    <path d="M46.1269 40H45.6973L40.7132 34.3831H40.6676V39.8424H39.2434V31.3205H39.673L44.6683 36.9152H44.7138V31.4793H46.1269V40Z" fill="black"/>
    <path d="M48.1383 39.8424V31.4793H52.7606V32.8124H49.6069V34.9371H52.2399V36.2702H49.6069V38.5081H52.7606V39.8412H48.1383V39.8424Z" fill="black"/>
    <path d="M57.2585 40C55.3936 40 54.5455 38.9488 54.4556 37.4236H55.9697C56.0485 38.4071 56.7489 38.7124 57.2474 38.7124C57.9368 38.7124 58.4907 38.1696 58.4907 37.5024C58.4907 36.6099 57.7558 36.3834 56.7046 35.9883C56.0497 35.7618 54.6021 35.2756 54.6021 33.6938C54.6021 32.213 55.9352 31.3205 57.2807 31.3205C58.0611 31.3205 58.8514 31.6147 59.3376 32.1908C59.7438 32.677 59.8238 33.1633 59.8571 33.6039H58.3541C58.2864 33.1743 58.0266 32.6093 57.2351 32.6093C56.6135 32.6093 56.1162 33.0611 56.1162 33.6606C56.1162 34.4287 56.6479 34.5986 58.0032 35.1303C59.4275 35.6843 60.0035 36.4979 60.0035 37.5036C60.0048 38.7678 59.1345 40 57.2585 40Z" fill="black"/>
    <path d="M64.2885 40C62.4236 40 61.5755 38.9488 61.4856 37.4236H62.9997C63.0785 38.4071 63.7789 38.7124 64.2774 38.7124C64.9668 38.7124 65.5207 38.1696 65.5207 37.5024C65.5207 36.6099 64.7858 36.3834 63.7346 35.9883C63.0797 35.7618 61.6321 35.2756 61.6321 33.6938C61.6321 32.213 62.9652 31.3205 64.3107 31.3205C65.0911 31.3205 65.8814 31.6147 66.3676 32.1908C66.7738 32.677 66.8538 33.1633 66.8871 33.6039H65.3841C65.3164 33.1743 65.0566 32.6093 64.2651 32.6093C63.6435 32.6093 63.1462 33.0611 63.1462 33.6606C63.1462 34.4287 63.678 34.5986 65.0332 35.1303C66.4575 35.6843 67.0335 36.4979 67.0335 37.5036C67.0348 38.7678 66.1645 40 64.2885 40Z" fill="black"/>
    <path d="M75.745 20.1656L75.7401 20.2837C75.6552 22.1573 74.9978 23.6603 73.7866 24.7509C71.6447 26.6798 68.4898 26.5173 67.6749 26.5075C67.6663 26.5075 67.5333 26.5075 67.5259 26.5075V17.7787C67.5653 17.7787 67.7278 17.7787 67.7684 17.7775C69.2973 17.7677 71.0772 17.6298 71.3739 21.447L71.3825 21.5602H71.6767V13.6083H71.3862L71.3739 13.7178C70.9825 17.3393 69.1533 17.3245 67.6626 17.3282C67.6576 17.3282 67.5296 17.3282 67.5259 17.3282V9.0057C67.7229 9.00447 68.0454 9.002 68.2436 8.99831C71.0551 8.95523 73.8407 8.66595 75.0262 14.6472L75.0459 14.7469H75.345V8.54162H59.1837V8.96877H60.6141V22.0637C60.5796 23.9446 59.8435 25.0993 59.1973 25.711C58.4168 26.4508 57.3237 26.8386 56.3353 26.8041C52.6892 26.6724 52.587 23.0054 52.5846 22.8466V8.96877H54.2451V8.54162H47.7863V8.96877H49.4998L49.4948 21.3128C49.4948 24.6881 51.2096 27.2916 56.2171 27.2916C59.5752 27.2916 61.1163 24.5022 61.0819 22.1893V8.96877H62.6046V8.97123H64.4104V26.5013H62.7806V26.942H76.0823V20.1656H75.745Z" fill="black"/>
    <path d="M42.1054 19.0676V19.4959H43.5924V23.3328C43.5899 26.1985 41.6893 26.8361 40.1986 26.8657C38.5898 26.8915 37.5115 26.1037 36.9009 24.4678C36.299 22.8724 35.9814 21.3325 35.9814 17.7467C35.9814 16.4702 36.1783 12.2837 37.1212 10.4521C37.4795 9.7615 38.1466 8.47761 40.1359 8.47761C40.142 8.47761 40.1482 8.47761 40.1543 8.47761C42.5793 8.48746 43.9728 10.1665 45.1028 14.6681L45.1262 14.7617H45.4007V8H45.136L45.104 8.07632C44.8455 8.69549 44.2288 8.8309 43.697 8.81859C43.4435 8.80874 43.212 8.77181 43.0422 8.73734C42.892 8.70411 42.796 8.67333 42.791 8.6721C42.5572 8.59825 42.3504 8.52808 42.1608 8.4653C42.0746 8.43576 41.9909 8.41114 41.9072 8.38775C41.2954 8.18464 40.8387 8.05539 40.1309 8.05539C36.9428 8.05539 32.5556 11.2953 32.5556 17.9794C32.5556 23.4387 35.8349 27.294 40.3107 27.294C41.144 27.294 41.8321 27.0208 42.4796 26.7204C42.6002 26.6712 42.7196 26.6207 42.8341 26.5666C43.4668 26.2662 44.0663 25.9818 44.7409 25.9461C44.768 25.9449 44.795 25.9449 44.8221 25.9437C44.8406 25.9437 44.8591 25.9425 44.8787 25.9425C45.269 25.9425 46.0519 26.1948 46.2033 26.9457L46.223 27.0442L46.4852 27.0454V19.4984H47.8934V19.07H42.1054V19.0676Z" fill="black"/>
    <path d="M23.0649 8.09479C19.2834 8.09479 15.14 11.8738 15.14 17.6433C15.14 23.4128 19.4201 27.2424 23.0649 27.2424C26.711 27.2424 30.9898 23.4128 30.9898 17.6433C30.9911 11.8738 26.8464 8.09479 23.0649 8.09479ZM27.4841 16.9343C27.4853 16.9762 27.4865 17.018 27.4865 17.0586C27.4878 17.0894 27.4878 17.1189 27.4878 17.1497C27.4878 17.1731 27.489 17.1977 27.489 17.2211C27.489 17.2593 27.4902 17.2962 27.4902 17.3319C27.4902 17.3454 27.4902 17.3602 27.4902 17.3738C27.4902 17.4131 27.4915 17.4501 27.4915 17.487C27.4915 17.4968 27.4915 17.5067 27.4915 17.5165C27.4915 17.5609 27.4915 17.6027 27.4915 17.6433C27.4915 17.6753 27.4915 17.7098 27.4915 17.7455C27.4865 18.8127 27.3967 22.9389 26.3159 24.9959C25.7066 26.1874 24.5015 26.8177 23.0649 26.8177C21.6284 26.8177 20.4233 26.1874 19.8127 24.9946C18.732 22.9389 18.6421 18.8115 18.6372 17.7455C18.6372 17.7098 18.6372 17.6753 18.6372 17.6433C18.6372 17.6039 18.6372 17.5621 18.6372 17.5165C18.6372 17.5067 18.6372 17.4968 18.6372 17.487C18.6372 17.4501 18.6372 17.4131 18.6384 17.3738C18.6384 17.3602 18.6384 17.3454 18.6384 17.3319C18.6384 17.2962 18.6396 17.2593 18.6396 17.2211C18.6396 17.1977 18.6409 17.1731 18.6409 17.1497C18.6409 17.1202 18.6421 17.0894 18.6421 17.0586C18.6433 17.018 18.6433 16.9762 18.6446 16.9343C18.6446 16.9195 18.6458 16.9048 18.6458 16.89C18.6876 15.2454 18.8932 12.1557 19.8127 10.3401C20.5587 8.83582 22.0494 8.5207 23.0662 8.5207C24.0817 8.5207 25.5724 8.83705 26.3171 10.3425C27.2367 12.157 27.441 15.2467 27.4828 16.8912C27.4828 16.9048 27.4841 16.9195 27.4841 16.9343Z" fill="black"/>
    <path d="M13.1028 8.54408V8.9663H14.6082L10.5929 22.4022L5.74411 8.9663H7.12033V8.72011H7.12156V8.54408H1V8.9663H2.77258L9.56501 27.0417H9.66226L15.0723 8.9663H16.7304V8.54408H13.1028Z" fill="black"/>
    </svg>
        );