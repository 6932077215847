import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children, initialUserEmail = null }) => {
  const [userEmail, setUserEmail] = useState(initialUserEmail);

  useEffect(() => {
    if (initialUserEmail) {
      setUserEmail(initialUserEmail);
    }
  }, [initialUserEmail]);

  return (
    <UserContext.Provider value={{ userEmail, setUserEmail }}>
      {children}
    </UserContext.Provider>
  );
};
