import { COMPONENTS, BEACON_VIEWS, DELAY_THRESHOLD } from '../Constants';
import { getDeviceInfo } from '../functions';

const isDev = window.location.host.includes('localhost');
const API_URL = isDev
  ? 'http://localhost:8080/api/log-event'
  : '/api/log-event';

export const logEvent = async ({
  level,
  message,
  additionalData = {},
  includeDevice = true,
}) => {
  /*
  By default, Winston provides the following log levels, ordered from the most severe to the least:
  -> error: Used for logging error messages.
  -> warn: Used for logging warnings.
  -> info: Used for informational messages (the default level).
  -> http: Used for logging HTTP requests (a lower level than info).
  -> verbose: Used for logging verbose messages.
  -> debug: Used for detailed debugging information.
  -> silly: Used for the most verbose, fine-grained debugging messages (lowest level).
  */
  const device = includeDevice ? getDeviceInfo() : {};
  const logData = {
    level, // 'info', 'error', etc.
    message,
    timestamp: new Date().toISOString(),
    path: window.location.pathname,
    ...additionalData,
    ...device,
  };

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logData),
    });

    // Check if the response is ok (status code 200-299)
    if (!response.ok) {
      throw new Error('Failed to send log');
    }

    const responseData = await response.json();
    console.log('Log sent to backend:', responseData);
  } catch (err) {
    console.error('Error sending log to backend:', err);
  } finally {
    // do nothing
  }
};

export const logComponentError = ({ error, errorInfo = {} }) => {
  logEvent({
    level: 'error',
    message: `Component Error: ${error.message}`,
    additionalData: {
      errorInfo,
    },
  });
};

export const logQlikHypercubeFetchError = ({ errorInfo = {}, componentName }) => {
  logEvent({
    level: 'error',
    message: `Hypercube call Error: ${errorInfo.message}`,
    additionalData: {
      errorInfo,
      componentName
    },
  });
};

export const logS3CachedDataFetchError = ({ errorInfo = {} }) => {
  logEvent({
    level: 'error',
    message: `S3 Cached data fetch error`,
    additionalData: {
      errorInfo,
    },
  });
};

export const logQlikHypercubeFetchDuration = ({
  componentName,
  duration,
  view,
  tab,
  email
}) => {
  logEvent({
    level: 'warn',
    message: `hypercube data load duration`,
    additionalData: {
      componentName,
      duration,
      view,
      tab,
      email
    },
  });
};

export { COMPONENTS, BEACON_VIEWS, DELAY_THRESHOLD };
